import React from 'react';
import { IoMdMail, IoMdCall } from 'react-icons/io';
import {MdLocationPin} from 'react-icons/md'
 
export default function Contact() {
  return (
    <div className="container-xxl py-5 contact-us" id='section5'>
      <div className="custom-container">
        <div className="text-start wow fadeInUp" data-wow-delay="0.1s">
          <h1 className="mb-5 contact-header">Contact Us</h1>
        </div>
        <div className="row g-4">
          <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
            <div className="d-flex align-items-center mb-4">
              <div className="icon-circle">
                <IoMdMail className="blue-icon" size={24} />
              </div>
              <p className="icon-details">info@intelliafrica.solutions</p>
            </div>
            <div className="d-flex align-items-center mb-4">
              <div className="icon-circle">
                <IoMdCall className="blue-icon" size={24} />
              </div>
              <p className="icon-details">+263 242 498460 / +263 78 844 8616</p>
            </div>
            {/* <div className="d-flex align-items-center">
              <div className="icon-circle">
                <MdLocationPin className="blue-icon" size={24} />
              </div>
              <p className="icon-details">7 Richmond Close, Highlands, Harare</p>
            </div> */}
            <iframe
              className="position-relative rounded mt-4"
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d121576.01846155786!2d31.01491199999999!3d-17.779916799999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2szw!4v1698048376151!5m2!1sen!2szw"
              frameBorder="0"
              style={{ border: 0, width: '100%', height: '400px' }} 
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
              title="Google Maps"
            ></iframe>
          </div>
          <div className="col-md-6">
            <div className="wow fadeInUp" data-wow-delay="0.2s">
              <form>
                <div className="row g-3">
                    <div className="col-md-12">
                        <div className="form-floating">
                        <input
                            type="text"
                            className="form-control form-control-lg"
                            id="name"
                            placeholder=" Name"
                            style={{ height: '80px' }}
                        />
                        <label htmlFor="name"></label>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-floating">
                        <input
                            type="email"
                            className="form-control form-control-lg"
                            id="email"
                            placeholder=" Email"
                            style={{ height: '80px' }}
                        />
                        <label htmlFor="email"></label>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-floating">
                        <textarea
                            className="form-control form-control-lg"
                            placeholder="Leave a message here"
                            id="message"
                            style={{ height: '300px' }}
                        ></textarea>
                        <label htmlFor="message"></label>
                        </div>
                    </div>
                    <div className="col-12">
                        <button className="btn btn-primary w-100 py-3 contact-button" type="submit">
                       SUBMIT
                        </button>
                    </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
