import React from 'react';
import { Link } from 'react-router-dom';
import { RiFacebookCircleLine } from 'react-icons/ri';
import { BsInstagram } from 'react-icons/bs';
import { BiLogoFacebook, BiLogoLinkedin } from 'react-icons/bi';


export default function Footer() {
  return (
    <footer className="footer text-white text-lg-start">
      <div className="container pt-5">
        <div className="row">
          <div className="col-lg-6 col-md-12 mb-4 mb-md-0">
            <h5 className="text-uppercase">Follow Us</h5>
            <div className="d-flex">
              <div className="circle-icon">
                <BiLogoFacebook size={32} color="blue" />
              </div>
              <div className="circle-icon">
                <BsInstagram size={32} color="blue" />
              </div>
              <div className="circle-icon">
                <BiLogoLinkedin size={32} color="blue" />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase">Quick Links</h5>
            <ul className="list-unstyled mb-0">
              <li>
                <a href="#section5" className="text-white">Contact Us</a>
              </li>
              <li>
                <a href="#section2" className="text-white">About Us</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase mb-0">Cookies and Policy</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/privacypolicy" className="text-white">Privacy Policy</Link>
              </li>
              <li>
              <Link to="/termsandconditions" className="text-white">Terms & Conditions</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}