import React from 'react'

export default function About() {
  return (
    <section className="section video" id='section2' data-section="section2">
        <div className="col-md-6 align-self-start">
            <div className="left-content">
                <h4>About Us</h4>
                <div className="divider"></div>
                <p>Intelli Africa Solutions (IAS) is a fintech company that was established in July 2018. 
                    The company aims to bridge the gap between technological advancements in financial services by developing tech solutions that solve
                        existing B2B and B2C inefficiencies. Their goal is to encourage financial inclusion and promote equal access to technology.
                </p>
            </div>
        </div>
    </section>
  )
}
