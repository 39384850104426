import React from 'react'

export default function Team() {
  return (
    <section className="section team" id="section4" data-section="section4">
        <div className="left-content">
            <h4>Our Team </h4>
            <div className="divider"></div>
        </div>
</section>
  )
}
