import React from 'react'

export default function Products() {
  return (
    <>
    <section className="section products" id='section3'>
            <div className="left-content">
                <h4>Products & Services</h4>
                <div className="divider"></div>
            </div>
    </section>
    <div class="container-fluid products-container">
    <div className="left-content">
                <h4>Products & Services</h4>
            </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-6">
        <div class="text-center">
          <div class="product-icon">
          <img className='product-icon' src="assets/icons/IAS_Website-02.png" alt="Icon"/>
          </div>
          <div class="product-description">
            <h2>Payment aggregations </h2>
            <h4>( Airtime, Electricity, Dstv, City Councils, Etc)</h4>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-6">
        <div class="text-center">
          <div class="product-icon">
            <img className='product-icon' src="assets/icons/IAS_Website-03.png" alt="Icon"/>
          </div>
          <div class="product-description">
            <h2>Remittance Aggregation</h2>
            <h4>(Local and Cross-Border)</h4>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-6">
        <div class="text-center">
          <div class="product-icon">
          <img className='product-icon' src="assets/icons/IAS_Website-04.png" alt="Icon"/>
          </div>
          <div class="product-description">
            <h2>Web App Development</h2>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-6">
        <div class="text-center">
          <div class="product-icon">
          <img className='product-icons' src="assets/icons/IAS_Website-05.png" alt="Icon"/>
          </div>
          <div class="product-description">
            <h2>Mobile App Development</h2>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-6">
        <div class="text-center">
          <div class="product-icon">
          <img className='product-icons' src="assets/icons/IAS_Website-06.png" alt="Icon"/>
          </div>
          <div class="product-description">
            <h2>Conversational Chatbots</h2>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-6">
        <div class="text-center">
          <div class="product-icon">
          <img className='product-icons' src="assets/icons/IAS_Website-07.png" alt="Icon"/>
          </div>
          <div class="product-description">
            <h2>Enterprise Solutions</h2>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <div class="text-center">
          <div class="product-icon">
          <img className='product-icons' src="assets/icons/IAS_Website-08.png" alt="Icon"/>
          </div>
          <div class="product-description">
           <h2> SMS Bulk Services</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
    </>
  )
}
