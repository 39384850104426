import React from 'react';
import Navbar from '../components/Navbar';
import MainBanner from '../components/MainBanner';
import About from '../components/About';
import WhatWeDo from '../components/WhatWeDo';
import Team from '../components/Team';
import Products from '../components/Products';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

function Index() {
  const sectionIds = ['#about', '#what-we-do', '#team', '#products', '#contact'];

  return (
    <>
      <Navbar sectionIds={sectionIds} />
      <MainBanner />
      <About key="about" />
      <WhatWeDo key="what-we-do" />
      <Team key="team" />
      <Products key="products" />
      <Contact key="contact" />
      <Footer />
     </>
  );
}

export default Index;