import React from 'react'

export default function WhatWeDo() {
  return (
    <section className="section what-we-do" id='section6'>
        <div className="col-md-6 align-self-start">
            <div className="left-content">
                <h4>What We Do </h4>
                <div className="divider"></div>
                <p>Intelli Africa Solutions provides technical solutions to individuals, small and medium-sized businesses, corporations, and institutions.</p>
                <p>IAS aims to improve the lives of both the banked and unbanked by continually innovating technical solutions on behalf of clients, businesses, and financial institutions.</p>

            </div>
        </div>
    </section>
  )
}
