import React from 'react'

export default function MainBanner() {
  return (
    <section className="section main-banner" id="top" data-section="section1">
      <video autoPlay muted loop id="bg-video">
          <source src="assets/images/bg.mp4" type="video/mp4" />
      </video>

      <div className="video-overlay header-text">
          <div className="caption">
            {/* <h2>INTELLI AFRICA SOLUTIONS</h2>
              <h4>YOUR FAVOURITE FINTECH</h4>
   */}
          </div>
      </div>
  </section>
  )
}
