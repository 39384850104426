import React, { Component } from 'react';
import { FaBars } from 'react-icons/fa';

export class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavOpen: false,
      activeSection: 'section1',
      isMainMenuVisible: false, 
    };
    this.sectionRefs = {}; 
  }

  componentDidMount() {
    const sectionOptions = {
      rootMargin: '-50% 0px -50% 0px', 
    };

    // Create an observer to track section visibility
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const sectionId = entry.target.getAttribute('id');
          this.setState({ activeSection: sectionId });
        }
      });
    }, sectionOptions);

    // Observe each section
    Object.keys(this.sectionRefs).forEach((sectionId) => {
      this.observer.observe(this.sectionRefs[sectionId]);
    });
  }

  componentWillUnmount() {
    // Disconnect the observer when the component is unmounted
    this.observer.disconnect();
  }

  handleToggleNav = () => {
    this.setState((prevState) => ({
      isNavOpen: !prevState.isNavOpen,
      isMainMenuVisible: !prevState.isNavOpen
    }));
  };

  handleSetActiveSection = (sectionId) => {
    this.setState({
      activeSection: sectionId,
      isMainMenuVisible: false, 
    });
  
    if (this.props.sectionIds.includes(sectionId)) {
      const section = document.querySelector(sectionId);
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  render() {
    const { isNavOpen, activeSection, isMainMenuVisible } = this.state;

    return (
      <>
        <header className="main-header clearfix" role="header">
        <div className="logo">
            <a href="/" id="logo">
              <img src="assets/images/logo.png" width={'150px'} alt="" />
            </a>
          </div>
          <a  className={`menu-link${isNavOpen ? ' active' : ''}`} onClick={this.handleToggleNav}>
            <FaBars />
          </a>

          <nav
            className={`main-nav${isNavOpen ? ' active' : ''}`}
            id="menu"
            role="navigation"
          >
            <ul
              className={`main-menu navbar-menu${isNavOpen ? ' active' : ''}${isMainMenuVisible ? '' : ' hidden'}`}
              id="menu-border"
            >
              <li
                className={activeSection === 'section2' ? 'active' : ''}
                onClick={() => this.handleSetActiveSection('section2')}
              >
                <a href="#section2">About Us</a>
              </li>
              <li
                className={activeSection === 'section6' ? 'active' : ''}
                onClick={() => this.handleSetActiveSection('section6')}
              >
                <a href="#section6">Our Solutions</a>
              </li>
              <li
                className={activeSection === 'section4' ? 'active' : ''}
                onClick={() => this.handleSetActiveSection('section4')}
              >
                <a href="#section4">Our Team</a>
              </li>
              <li
                className={activeSection === 'section3' ? 'active' : ''}
                onClick={() => this.handleSetActiveSection('section3')}
              >
                <a href="#section3">Services</a>
              </li>


              <li
                className={activeSection === 'section5' ? 'active' : ''}
                onClick={() => this.handleSetActiveSection('section5')}
              >
                <a href="#section5">Contact Us</a>
              </li>
            </ul>
          </nav>
        </header>
      </>
    );
  }
}

export default Navbar;